<script setup lang="ts">
import { OnClickOutside } from '@vueuse/components'
import CloseIcon from '../ui/icons/CloseIcon.vue'

defineProps<{
  amount: number
  name: string | null
}>()

const isMobile = inject<Ref<boolean>>('isMobile')

const { t } = useLocale()
const { format } = useFormatAmount()

const serviceFeeInfoImg = ref()
const serviceFeeTooltip = ref(false)
const tooltipArrowLeft = ref(0)

const toggleServiceFeeTooltip = (ev: MouseEvent) => {
  if (isMobile?.value) {
    const { pageX } = ev
    // page left img - 16 (padding left) - 8 (1/2 width of image)
    tooltipArrowLeft.value = pageX - 16 - 8
  } else tooltipArrowLeft.value = 0

  serviceFeeTooltip.value = true
}
</script>

<template>
  <div class="flex flex-row items-center gap-2">
    <p>
      {{ name ?? t('shoppingCart.serviceFee.name') }}
    </p>
    <div class="relative">
      <img
        ref="serviceFeeInfoImg"
        class="h-auto w-4 cursor-pointer"
        data-test="service-fee-terms"
        src="/images/icons/info.svg"
        alt="Service fee terms icon button"
        @click="toggleServiceFeeTooltip"
      />
      <OnClickOutside
        v-if="serviceFeeTooltip"
        :options="{ ignore: [serviceFeeInfoImg] }"
        @trigger="serviceFeeTooltip = false"
      >
        <div
          class="after:content-[' '] absolute bottom-8 left-4 top-auto flex h-fit w-[calc(100vw-32px)] -translate-x-[calc(var(--tooltipArrowLeft)+16px)] flex-row rounded-md bg-mcd-neutralGrey p-4 after:absolute after:left-[var(--tooltipArrowLeft)] after:top-[100%] after:block after:h-0 after:w-0 after:rotate-180 after:border-[10px] after:border-transparent after:border-b-mcd-neutralGrey md:bottom-[calc(100%+16px)] md:left-0 md:right-0 md:z-20 md:w-[300px] md:after:left-4 md:after:top-[100%]"
          :style="{ '--tooltipArrowLeft': `${tooltipArrowLeft}px` }"
        >
          <p class="prose prose-sm mt-0 leading-4">
            {{ t('shoppingCart.serviceFee.info') }}
          </p>
          <CloseIcon width="25" height="20" @click="serviceFeeTooltip = false" />
        </div>
      </OnClickOutside>
    </div>
  </div>
  <span class="block">{{ format(amount) }}</span>
</template>
